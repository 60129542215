<template>
  <v-form ref="form" v-model="valid" lazy-validation class="container">
    <v-card v-if="isInRole('29') || isInRole('30')">
      <v-card-title>
        <v-row>
          <v-col cols="12" lg="8" ms="8" sm="8">
            <v-tabs v-model="tab" align-with-title>
              <v-tabs-slider color=""></v-tabs-slider>

              <v-tab v-for="item in items" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>
          </v-col>
          <v-col
            align-self="center"
            style="text-align: end"
            cols="12"
            lg="4"
            ms="4"
            sm="4"
          >
            <v-spacer></v-spacer>
            <v-chip v-if="editedItem.patientDisplayName" label>{{
              editedItem.patientDisplayName
            }}</v-chip>
          </v-col>
        </v-row>
      </v-card-title>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="3" sm="6">
                    <v-row style="position: relative" no-gutters>
                      <v-col v-if="url != null && url != ''">
                        <v-card
                          class="pa-1"
                          style="position: absolute; top: -30px; z-index: 1"
                          width="50%"
                          flat
                        >
                          <v-img
                            max-width="100"
                            contain
                            max-height="110"
                            :src="url"
                          ></v-img>
                        </v-card>
                        <!-- <v-btn
                          style="position:absolute;top:15px;left:0px;z-index:1"
                          icon
                          @click="image = null"
                        >
                          <v-icon>
                            mdi-close
                          </v-icon>
                        </v-btn> -->
                      </v-col>

                      <v-file-input
                        :rules="[
                          (value) =>
                            !value ||
                            value.size < 2000000 ||
                            $t('error.FileIsTooBig'),
                        ]"
                        prepend-icon="mdi-camera"
                        accept="image/png, image/jpeg, image/bmp"
                        @change="Preview_image"
                        v-model="image"
                        :label="$t('patients.patientPicture')"
                      >
                      </v-file-input>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="editedItem.patientFirstName"
                      :label="$t('patients.patientFirstName')"
                      :rules="rules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="editedItem.patientMiddleName"
                      :label="$t('patients.patientMiddleName')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="editedItem.patientLastName"
                      :label="$t('patients.patientLastName')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="editedItem.patientFourName"
                      :label="$t('patients.patientFourName')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="6">
                    <v-select
                      v-model="countryGuid"
                      :items="countries"
                      :rules="rules"
                      :label="$t('country.countryName')"
                      item-text="countryName"
                      item-value="guid"
                      return-object
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3" sm="6">
                    <v-select
                      v-model="editedItem.cityGuid"
                      :rules="rules"
                      :items="filterCities"
                      :label="$t('city.cityName')"
                      item-text="cityName"
                      item-value="guid"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3" sm="6">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="editedItem.patientDob"
                          :label="$t('patients.patientDob')"
                          :rules="rules"
                          persistent-hint
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.patientDob"
                        active-picker.sync="year"
                        :rules="rules"
                        min="1750-01-01"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="3" sm="6">
                    <v-select
                      :items="gender"
                      :label="$t('patients.patientGender')"
                      v-model="editedItem.patientGender"
                      item-text="text"
                      item-value="value"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="editedItem.patientWork"
                      :label="$t('patients.patientWork')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="editedItem.patientAddress"
                      :label="$t('patients.patientAddress')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="editedItem.patientMobile"
                      :label="$t('phone')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="editedItem.email"
                      :label="$t('email')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="editedItem.emergencyContactName"
                      :label="$t('patients.emergencyContactName')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="editedItem.emergencyContactNumber"
                      :label="$t('patients.emergencyContactNumber')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="editedItem.husbandOrWife"
                      :label="$t('patients.husbandName')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="editedItem.husbandOrWifeMobile"
                      :label="$t('patients.husbandMobile')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="editedItem.familyNumber"
                      :label="$t('patients.familyNumber')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="editedItem.patientNote"
                      :label="$t('notes')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="8" sm="6" class="mx-2">
                    <v-row align="center">
                      <v-radio-group
                        hide-details
                        v-model="editedItem.bloodGroup"
                        row
                        :label="$t('patients.bloodGroup')"
                      >
                        <v-radio label="A" value="A"></v-radio>
                        <v-radio label="B" value="B"></v-radio>
                        <v-radio label="AB" value="AB"></v-radio>
                        <v-radio label="O" value="O"></v-radio>
                      </v-radio-group>
                      <v-text-field
                        v-model="editedItem.bloodGroupNote"
                        :label="$t('notes')"
                        :disabled="!editedItem.bloodGroup"
                        class="mx-2"
                      ></v-text-field>
                    </v-row>
                    <!-- <v-text-field
                      v-model="editedItem.bloodGroup"
                      label="Blood Group"
                    ></v-text-field> -->
                  </v-col>
                  <!-- <v-col cols="12" md="3" sm="6">
                    <v-text-field
                      v-model="editedItem.bloodGroupNote"
                      label="BloodGroupNote"
                    ></v-text-field>
                  </v-col> -->
                  <v-col cols="12" md="8" sm="6" class="mx-2">
                    <v-row align="center">
                      <v-checkbox
                        v-model="editedItem.isHasAllergy"
                        hide-details
                        :label="$t('patients.isHasAllergy')"
                        class="shrink mx-2 mt-0"
                      ></v-checkbox>
                      <v-text-field
                        v-model="editedItem.allergyNote"
                        :label="$t('notes')"
                        :disabled="!editedItem.isHasAllergy"
                      ></v-text-field>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="8" sm="6" class="mx-2">
                    <v-row align="center">
                      <v-checkbox
                        v-model="editedItem.isHasBloodPressure"
                        hide-details
                        :label="$t('patients.isHasBloodPressure')"
                        class="shrink mx-2 mt-0"
                      ></v-checkbox>
                      <v-text-field
                        v-model="editedItem.bloodPressureNote"
                        :label="$t('notes')"
                        :disabled="!editedItem.isHasBloodPressure"
                      ></v-text-field>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="8" sm="6" class="mx-2">
                    <v-row align="center">
                      <v-checkbox
                        v-model="editedItem.isHasDiabetic"
                        hide-details
                        :label="$t('patients.isHasDiabetic')"
                        class="shrink mx-2 mt-0"
                      ></v-checkbox>
                      <v-text-field
                        v-model="editedItem.diabeticNote"
                        :label="$t('notes')"
                        :disabled="!editedItem.isHasDiabetic"
                      ></v-text-field>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="8" sm="6" class="mx-2">
                    <v-row>
                      <v-text-field
                        v-model="editedItem.weight"
                        :label="$t('patients.weight')"
                        append-icon="mdi-weight"
                        class="px-2"
                        type="number"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedItem.weightNote"
                        :label="$t('notes')"
                        :disabled="!editedItem.weight"
                      ></v-text-field>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="8" sm="6" class="mx-2">
                    <v-row>
                      <v-text-field
                        v-model="editedItem.height"
                        :label="$t('patients.height')"
                        type="number"
                        append-icon="mdi-human-male-height-variant"
                        class="px-2"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedItem.heightNote"
                        :label="$t('notes')"
                        :disabled="!editedItem.height"
                      ></v-text-field>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-card-actions>
        <v-btn color="blue darken-1" class="white--text" @click="clear">
          <v-icon>mdi-newspaper-variant-outline</v-icon>
          {{ $t("new") }}
        </v-btn>

        <v-btn
          color="green darken-1"
          class="white--text"
          @click="save"
          :disabled="!valid"
          :min-width="100"
        >
          <v-icon>mdi-content-save-outline</v-icon>
          {{ $t("save") }}
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn to="/patients" color="" text>
          {{ $t("backToList") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import axios from "axios";
export default {
  props: [],

  data() {
    return {
      guid: this.$route.params.guid,

      activePicker: null,
      url: require("@/assets/user-icon.svg"),
      image: null,
      tab: null,
      menu: false,
      valid: true,
      dateFormatted: null,
      gender: [
        { text: this.$t("patients.male"), value: 0 },
        { text: this.$t("patients.female"), value: 1 },
      ],
      items: [this.$t("patients.personal"), this.$t("patients.medical")],
      countryGuid: "",
      countries: [],
      cities: [],
      filterCities: [],
      editedItem: {
        cityGuid: "",
        patientFirstName: "",
        patientMiddleName: "",
        patientLastName: "",
        patientFourName: "",
        patientWork: "",
        patientGender: 0,
        patientDob: "1950-01-01",
        patientNote: "",
        patientAddress: "",
        patientMobile: null,
        email: null,
        emergencyContactName: "",
        emergencyContactNumber: null,
        husbandOrWife: "",
        husbandOrWifeMobile: null,
        familyNumber: null,
        bloodGroup: "O",
        bloodGroupNote: "",
        allergyNote: "",
        bloodpressureNote: "",
        diabeticNote: "",
        weightNote: "",
        heightNote: "",
        notex: "",
        isHasAllergy: false,
        isHasBloodpressure: false,
        isHasDiabetic: false,
        isActive: true,
        weight: 0,
        height: 0,
      },
      defaultPatient: {
        patientDob: "1991-01-01",
        bloodGroup: "O",
        patientGender: 0,
      },

      rules: [(value) => !!value || this.$t("ThisFieldIsRequired")],
    };
  },
  created() {
    this.getDefaultCity();
  },

  watch: {
    countryGuid(val) {
      this.filterCities = val.cities;
    },
    image(val) {
      if (!val || val == null) {
        this.url = require("@/assets/user-icon.svg");
      }
    },
  },

  computed: {
    //computedDateFormatted() {
    //return this.formatDate(this.editedItem.patientDob);
    //},
  },
  methods: {
    notEmptyGuid() {
      return this.guid != null && this.guid != undefined && this.guid != "";
    },
    getDefaultCity() {
      axios.get("Country").then((response) => {
        this.countries = response.data.data;

        if (this.notEmptyGuid()) {
          axios.get("Patient?guid=" + this.guid).then((response) => {
            this.editedItem = response.data.data.patient;
            this.editedItem.patientDob = this.$moment(
              this.editedItem.patientDob
            ).format("YYYY-MM-DD");

            this.countryGuid = response.data.data.patient.city.countryGuid;

            // this.url = this.getImageURL(
            //   this.guid,
            //   this.editedItem.modifyDate,
            //   "patient"
            // );
            this.url = this.editedItem.pictureUrl
              ? this.editedItem.pictureUrl
              : require("@/assets/user-icon.svg");
          });
        } else {
          axios.get("Setting/GetCountry").then((resp) => {
            this.countryGuid = resp.data.data.countryGuid;
            this.editedItem.cityGuid = resp.data.data.cityGuid;
          });
        }

        setTimeout(() => {
          this.filterCities = this.countries.filter((o) => {
            return o.guid == this.countryGuid;
          })[0].cities;
        }, 500);
      });
    },

    Preview_image() {
      if (this.image != null && this.image != "")
        this.url = URL.createObjectURL(this.image);
    },
    clear() {
      this.$router.push({
        path: "/patientregistration/",
      });
      this.editedItem = { ...this.defaultPatient };
      this.guid = null;
      this.url = require("@/assets/user-icon.svg");
      this.getDefaultCity();
    },
    save() {
      var val = this.$refs.form.validate();
      if (val) {
        var data = new FormData();
        if (this.image != null) {
          data.append("file", this.image);
        }

        for (var key in this.editedItem) {
          if (this.editedItem[key]) data.append(key, this.editedItem[key]);
        }

        if (this.notEmptyGuid()) {
          axios.post("Patient/Update", data).then((response) => {
            if (response.data.status == "Successful") {
              this.$toast(this.$t("operationAccomplishedSuccessfully"));
              var patient = response.data.data;
              patient.patientDob = this.$moment(patient.patientDob).format(
                "YYYY-MM-DD"
              );
              this.editedItem = patient;
              this.guid = patient.guid;
            } else {
              this.$toast.error(this.$t("error." + response.data.message));
            }
          });
        } else {
          axios
            .post("Patient/Add", data)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                var patient = response.data.data;
                patient.patientDob = this.$moment(patient.patientDob).format(
                  "YYYY-MM-DD"
                );
                this.editedItem = patient;
                this.guid = patient.guid;
                this.$router.push({
                  path: "/patientregistration/" + response.data.data.guid,
                });

                // setTimeout(() => {
                //   this.$router.push({ path: "/patients" });
                //   //window.location.href = "/patients";
                // }, 2000);
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
              // console.log(data);
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            });
        }
      }
    },
  },
};
</script>

<style></style>
